import type { Locale } from "./en";
const fr: Locale = {
  invalidJwtToken: "Sorry, but your token is invalid",
  "search...": "Recherche...",

  consumerName: "Client",

  myInformation: "Mes informations",
  profile: "Profil",
  "file.uploadHelper.uploadDigitalVehicleInspection":
    "Téléchargez une copie de votre inspection numérique du véhicule :",

  cantFindTheCustomer: "Vous ne trouvez pas le client ?d",
  searchForACustomer: "Rechercher un client",
  businessName: "Nom de l'entreprise",

  activePromotions: "Promotions actives",
  submissionDeadline: "Date limite de soumission",
  description: "Description",
  submitAClaim: "Soumettre une réclamation",
  requiredToQualify: "Nécessaire pour être admissible au rabais",
  terms: "Termes",
  lng: "English",
  vehicleYear: "Année",
  vehicleInformation: "Information du véhicule",
  vehicleMake: "Marque",
  vehicleMakeLoading: "Chargement des marques...",
  useVehicleLookup: "Utiliser la recherche de véhicules",
  vehicleMakeNotFound: "La marque du véhicule n'est pas listée",
  vehicleModel: "Modèle",
  vehicleModelLoading: "Chargement des modèles...",
  vehicleModelNotFound: "Le modèle du véhicule n'est pas listée",
  customerInformation: "Information du client",
  licensePlate: "No de plaque",
  firstName: "Prénom",
  lastName: "Nom de famille",
  phone: "Téléphone portable",
  address: "Adresse",
  addressLine2: "Unité/Appartement",
  city: "Ville",
  province: "Province",
  postal: "Code postal",
  helper_searchForACustomer:
    "Utilisez le champ de recherche ci-dessus pour rechercher un client.",
  helper_searchForAnAsp:
    "Use the searchbox above to search for your Automotive Service Provider.",
  helper_searchForParts:
    "Utilisez le champ de recherche ci-dessus pour ajouter des pièces à votre réclamation.",

  whatPartsWouldYouLikeToClaim: "Quelles pièces souhaitez-vous réclamer ?",

  error_phoneInUse:
    "Désolé, mais ce numéro de téléphone est déjà utilisé. Essayez de vous connecter.",
  error_emailInUse:
    "Désolé, mais cette adresse e-mail est déjà utilisée. Essayez de vous connecter.",
  thisTokenHasExpired: "Ce jeton a expiré.",
  incorrectSecurityCodePleaseTryAgain:
    "Code de sécurité incorrect. Veuillez réessayer.",
  thisTokenIsInvalid: "Ce jeton n'est pas valide.",
  location: "Emplacement",
  completeYourProfile: "Complétez votre profil",

  promoCode: "Code promotionnel",
  myClaims: "Mes réclamations",
  confirmationNumber: "Numéro de confirmation",
  automotiveServiceProvider: "Fournisseur de services automobiles",
  vehicle: "Véhicule",
  status: "Statut",
  submitOrTrackYourRebate: "Soumettre ou suivre votre remise",
  emailOrCellPhone: "Courriel ou téléphone cellulaire",
  next: "Suivant",
  beforeYouBegin:
    "Avant de commencer, vous aurez besoin des documents suivants :",
  originalServiceInvoiceCopy: "Copie de votre facture de service original",
  promoCodeFromYourRebateClaimForm: "Formulaire de demande de remboursement",
  weSentASecurityCode: "Nous vous avons envoyé un code de sécurité",
  partsRequiredForRebate: "Pièce (s) requise (s) pour la remise",
  invoiceInformation: "Informations sur la facture",
  invoiceNumber: "Numéro de facture",
  invoiceDate: "Date de la facture",
  "qty:": "Qté :",
  home: "Accueil",
  logOut: "Fermer la session",
  myNapaPrograms: "mynapaprograms.com",

  dashboard: "Tableau de bord",
  "file.uploadHelper.uploadFiles": "Téléchargez une copie de votre facture :",
  "file.uploadHelper.dragAndDrop": "glisser-déposer",
  "file.uploadHelper.or": "ou",
  "file.uploadHelper.selectAFile": "sélectionner un fichier",
  "rebateAmount:": "Montant du rabais :",
  submitClaim: "Soumettre une réclamation",
  subjectToApproval: "*Sous réserve de l'approbation de la réclamation.",

  email: "Email",

  error_vehicleYear: "Veuillez sélectionner une année de véhicule.",
  error_vehicleMake: "Veuillez sélectionner une marque de véhicule.",
  error_vehicleModel: "Veuillez sélectionner un modèle de véhicule.",
  error_invoiceNumber: "Veuillez saisir votre numéro de facture.",
  error_invoiceDate: "Veuillez sélectionner votre date de facture.",
  error_firstName: "Veuillez saisir le prénom de votre client.",
  error_lastName: "Veuillez saisir le nom de famille de votre client.",
  error_email: "S'il vous plaît, mettez une adresse email valide.",
  error_phone: "Veuillez entrer un numéro de téléphone portable valide.",
  error_address: "Veuillez entrer une adresse postale valide.",
  error_city: "Veuillez entrer une ville valide.",
  error_province: "Veuillez sélectionner une province.",
  error_postal: "Veuillez entrer un code postal valide.",
  error_installerNotFound:
    "Veuillez saisir le nom commercial de votre prestataire de services automobiles.",
};
export default fr;
